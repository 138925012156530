import React from 'react';

import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import { Banner, ContactBanner } from '../components/common';
import Benefits from '../components/modules/evaluaciones/Benefits';
import { FaFontAwesomeFlag } from 'react-icons/fa';

export const query = graphql`
  query CalificacionDeRiesgosQuery {
    site {
      siteMetadata {
        cmsUrl
      }
    }
  }
`;

const CalificacionDeRiesgos = ({ data, pageContext }) => {
  const { banner_image } = pageContext.data;
  const {
    top_banner_text,
    details_title,
    analysis_description,
    analysis_cards,
    ratings_description,
    ratings_cards,
    details_message,
    benefits,
    contact_banner_text,
    contact_banner_button,
  } = pageContext.translation;

  const cmsURL = data.site.siteMetadata.cmsUrl;

  return (
    <Layout pageId="riesgos" currentLanguage={pageContext.languageCode}>
      <Banner image={`${cmsURL}/assets/${banner_image.id}`} innerText={top_banner_text} />

      <section className="details">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto details__container">
              <div className="details__content">
                <h2>{details_title}</h2>
                {analysis_description
                  .split('\n')
                  .filter(p => p !== '')
                  .map(p => (
                    <p key={p}>{p}</p>
                  ))}
                <div className="analysis-cards">
                  {analysis_cards.map(card => {
                    return (
                      <div className="card">
                        <h3>{card.title}</h3>
                        {card.body
                          .split('\n')
                          .filter(p => p !== '')
                          .map(p => (
                            <p key={p}>{p}</p>
                          ))}
                      </div>
                    );
                  })}
                </div>
                {ratings_description
                  .split('\n')
                  .filter(p => p !== '')
                  .map(p => (
                    <p key={p}>{p}</p>
                  ))}
                <div className="ratings-cards">
                  {ratings_cards.map(card => {
                    return (
                      <div className="card">
                        <h3>{card.title}</h3>
                        {card.body
                          .split('\n')
                          .filter(p => p !== '')
                          .map(p => (
                            <p key={p}>{p}</p>
                          ))}
                      </div>
                    );
                  })}
                </div>
              </div>
              {details_message && (
                <p className="details__msg">
                  <span className="icon">
                    <FaFontAwesomeFlag />
                  </span>
                  <span>{details_message}</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </section>

      <Benefits content={benefits} link={false} />
      <ContactBanner
        innerText={contact_banner_text}
        buttonText={contact_banner_button}
        to={`${pageContext.basePath}/contact`}
        linkState={{ subject: 'Clasificación de Riesgo' }}
      />
    </Layout>
  );
};

export default CalificacionDeRiesgos;

export const Head = () => <SEO title="Credit Rating - MicroRate" />;
